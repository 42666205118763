import castoresLogo from '../assets/carriers/castores-logo.jpg';
import dhlLogo from '../assets/carriers/dhl-logo.png';
import estafetaLogo from '../assets/carriers/estafeta-logo.png';
import fedexLogo from '../assets/carriers/fedex-logo.png';
import grupoLogo from '../assets/carriers/grupo-logo.png';
import ivoyLogo from '../assets/carriers/ivoy-logo.png';
import jrLogo from '../assets/carriers/jr-logo.png';
import odmLogo from '../assets/carriers/odm-logo.png';
import planeacionLogo from '../assets/carriers/planeacion-logo.png';
import potosinosLogo from '../assets/carriers/potosinos-logo.png';
import redpackLogo from '../assets/carriers/redpack-logo.png';
import tresGuerrasLogo from '../assets/carriers/tresguerras-logo.png';
import zubutLogo from '../assets/carriers/zubut-logo.png';
import happymileLogo from '../assets/carriers/happymile-logo.svg';
import paquetexpress from '../assets/carriers/paquetexpress-logo.png';
import encaminoLogoDark from '../assets/logo-dark.svg';
import encaminoLogoWhite from '../assets/logo.svg';
import planeacionaduanera from '../assets/carriers/planeacion-logo.png';
import piticLogo from '../assets/carriers/pitic-logo.jpg';
import borzoLogo from '../assets/carriers/borzo-logo.png';
import halconLogo from '../assets/carriers/halcon-logo.png';
import sendexLogo from '../assets/carriers/sendex-logo.png';

export const getLogoByCarrierName = (carrier: string) => {
	switch (carrier) {
		case 'castores':
			return castoresLogo;
		case 'dhl':
			return dhlLogo;
		case 'estafeta':
			return estafetaLogo;
		case 'fedex':
			return fedexLogo;
		case 'grupo':
			return grupoLogo;
		case 'ivoy':
			return ivoyLogo;
		case 'jr':
			return jrLogo;
		case 'odm':
			return odmLogo;
		case 'planeacion':
			return planeacionLogo;
		case 'potosinos':
			return potosinosLogo;
		case 'redpack':
			return redpackLogo;
		case 'tresguerras':
			return tresGuerrasLogo;
		case 'zubut':
			return zubutLogo;
		case 'encamino-white':
			return encaminoLogoWhite;
		case 'happymile':
			return happymileLogo;
		case 'paquetexpress':
			return paquetexpress;
		case 'planeacionaduanera':
			return planeacionaduanera;
		case 'uber':
			return encaminoLogoDark;
		case 'pitic':
			return piticLogo;
		case 'halcon':
			return halconLogo;
		case 'borzo':
			return borzoLogo;
		case 'sendex':
			return sendexLogo;
		default:
			return encaminoLogoDark;
	}
};

export const getCarrierName = (carrier: string): string => {
	switch (carrier) {
		case 'castores':
			return 'Castores';
		case 'dhl':
			return 'DHL';
		case 'estafeta':
			return 'Estafeta';
		case 'fedex':
			return 'FedEx';
		case 'grupo':
			return 'Grupo';
		case 'ivoy':
			return 'iVoy';
		case 'jr':
			return 'Jr';
		case 'odm':
			return 'odm';
		case 'planeacion':
			return 'planeacion';
		case 'planeacionaduanera':
			return 'Planeación Aduanera';
		case 'potosinos':
			return 'Potosinos';
		case 'redpack':
			return 'Redpack';
		case 'tresguerras':
			return 'Tres Guerras';
		case 'zubut':
			return 'Zubut';
		case 'happymile':
			return 'Happymile';
		case 'paquetexpress':
			return 'Paquetexpress';
		case 'uber':
			return 'UBER';
		case 'pitic':
			return 'Pitic';
		case 'radio transportes':
			return 'Radio Transportes';
		case 'rhino':
			return 'Rhino';
		case 'halcon':
			return 'Halcón';
		case 'expressenvios':
			return 'Express Envíos';
		case 'borzo':
			return 'Borzo';
		case 'tiog':
			return 'Tiog';
		case 'sendex':
			return 'Sendex';
		case '':
			return '';
		default:
			return 'Encamino';
	}
};

export enum CARRIERS {
	BORZO = 'borzo',
	DHL = 'dhl',
	ESTAFETA = 'estafeta',
	EXPRESS = 'expressenvios',
	FEDEX = 'fedex',
	HALCON = 'halcón',
	IVOY = 'ivoy',
	JR = 'jr',
	PAQUETEXPRESS = 'paquetexpress',
	PITIC = 'pitic',
	PLANEACION = 'planeacionaduanera',
	POTOSINOS = 'potosinos',
	RADIOTRANSPORTES = 'radiotransportes',
	REDPACK = 'redpack',
	RHINO = 'rhino',
	SENDEX = 'sendex',
	TIOG = 'tiog',
	TRESGUERRAS = 'tresguerras',
	UBER = 'uber'
}

export const carriersRef = {
	DHL: CARRIERS.DHL,
	ESTAFETA: CARRIERS.ESTAFETA,
	FEDEX: CARRIERS.FEDEX,
	IVOY: CARRIERS.IVOY,
	JR: CARRIERS.JR,
	PAQUETEXPRESS: CARRIERS.PAQUETEXPRESS,
	PLANEACION: CARRIERS.PLANEACION,
	POTOSINOS: CARRIERS.POTOSINOS,
	REDPACK: CARRIERS.REDPACK,
	TRESGUERRAS: CARRIERS.TRESGUERRAS,
	UBER: CARRIERS.UBER,
	PITIC: CARRIERS.PITIC,
	RADIOTRANSPORTES: CARRIERS.RADIOTRANSPORTES,
	RHINO: CARRIERS.RHINO,
	HALCON: CARRIERS.HALCON
};

export const MANUAL_CARRIER_LIST = [
	CARRIERS.EXPRESS,
	CARRIERS.HALCON,
	CARRIERS.IVOY,
	CARRIERS.JR,
	CARRIERS.PITIC,
	CARRIERS.PLANEACION,
	CARRIERS.POTOSINOS,
	CARRIERS.TRESGUERRAS,
	CARRIERS.UBER,
	CARRIERS.RADIOTRANSPORTES,
	CARRIERS.RHINO
];
