import { Button, ButtonProps, makeStyles, CircularProgress } from '@material-ui/core';
import { ArrowRight, ArrowLeft, Save as SaveIcon, Send as SendIcon, Truck } from 'react-feather';

const useStyles = makeStyles((theme) => ({
	btn: {
		color: '#fff',
		background: 'linear-gradient(180deg, #57BBD8 0%, #0088D5 100%), #57BBD8',
		'&:hover': {
			background: '#1A84BF'
		},
		borderRadius: '8px',
		padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`,
		minWidth: 120
	},
	text: {
		padding: '0 6px'
	}
}));

interface Props extends ButtonProps {
	redirectTo?: string;
	children?: string;
	isLoading?: boolean;
	icon?: 'save' | 'arrow' | 'package' | 'create-guide' | 'arrow_left' | 'pickup';
}

export default function SaveButton({ redirectTo, children, icon, isLoading, ...props }: Props) {
	const classes = useStyles();

	return (
		<Button className={classes.btn} {...props}>
			{isLoading ? (
				<CircularProgress size={24} />
			) : (
				<>
					{icon === 'save' && <SaveIcon size='20' />}
					{icon === 'create-guide' && <SendIcon size='20' />}
					{icon === 'arrow_left' && <ArrowLeft size='20' />}
					{icon === 'pickup' && <Truck size='20' />}

					<span className={classes.text}>{children ?? 'Continuar'}</span>
					{!icon && <ArrowRight size='24' color='#fff' />}
				</>
			)}
		</Button>
	);
}
